import {Fragment, useRef} from "react";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import {useInfiniteQuery} from "react-query";

export default function InfiniteScroll({queryKey, next, nextPageParam, pageCreator}) {
    const loader = useRef();
    const {
        data,
        error,
        isError,
        isLoading,
        fetchNextPage,
        hasNextPage,
    } = useInfiniteQuery(
        queryKey,
        async ({pageParam}) => next(pageParam),
        {
            getNextPageParam: lastPage => (lastPage && lastPage[nextPageParam]) ?? false,
        }
    )

    useIntersectionObserver({
        target: loader,
        onIntersect: fetchNextPage,
        enabled: hasNextPage && !isError
    });

    return (
        <div>
            {isLoading ? (
                <p>Loading...</p>
            ) : isError ? (
                <span>Error: {error.message}</span>
            ) : (
                <>
                    {
                        data.pages.map(page => <Fragment
                            key={page[nextPageParam] ?? "only"}>{pageCreator(page)}</Fragment>)
                    }
                    <div className="loading" ref={loader}>
                        <h2>a</h2>
                    </div>
                </>
            )}

        </div>
    )
}