import {Col, Container, Row} from "react-bootstrap";
import styled from "styled-components";

const padding = "20px";

const PageContentContainer = styled(Col)`
  padding: 50px;
  background: white;

  box-shadow: 0 5px 10px 5px #0000002b;
  border-radius: 5px;
  
  height: calc(100% - ${padding} * 4);
  
  overflow: auto;
`

const PageWrapper = styled.div`
  padding: ${padding};
  height: 100%;
`

const StyledContainer = styled(Container)`
  height: 100%;
`

export default function PageContainer({children}) {
    return (
        <PageWrapper>
            <StyledContainer>
                    <PageContentContainer>
                        {children}
                    </PageContentContainer>
            </StyledContainer>
        </PageWrapper>
    )
}
