import {useHistory, useParams} from 'react-router-dom';
import {useState} from "react";
import {Button, ButtonToolbar, Tab, Tabs} from "react-bootstrap";
import {deleteImport, getImportStatus, rerunTransform, startImport, uploadImport} from "../../lib/api";
import {useMutation, useQuery, useQueryClient} from "react-query";
import ImportAnalysis from "../wrapper/ImportAnalysis";
import ImportErrors from "../wrapper/ImportErrors";
import AsyncButton from "../presentational/AsyncButton";


export default function ImportPage({location}) {
    const history = useHistory();

    const {importId} = useParams();
    const uploadUrl = location?.state?.uploadUrl;

    if (uploadUrl)
        return (
            <NewImportPage onUpload={() => history.replace(location.path)}
                           history={history}
                           uploadUrl={uploadUrl}
                           importId={importId}
            />
        )
    else return (
        <ImportStatusPage importId={importId} history={history}/>
    )
}

function NewImportPage({history, uploadUrl, onUpload, importId}) {
    const [file, setFile] = useState();
    const uploadMutation = useMutation(() => uploadImport(uploadUrl, file), {
        onSuccess: () => onUpload ? onUpload() : null
    });

    return (
        <div>
            New import
            {uploadUrl &&
            <div>
                Upload import
                <input type={'file'} name={'import'} onChange={(e) => setFile(e.target.files[0])}/>
                <ButtonToolbar>
                    <AsyncButton isLoading={uploadMutation.isLoading} as={Button} active={file}
                                 onClick={() => uploadMutation.mutate({})}>Upload</AsyncButton>
                    <Button variant={"danger"} onClick={async () => {
                        await deleteImport(importId);
                        history.replace('/');
                    }}>Cancel</Button>
                </ButtonToolbar>
            </div>
            }
        </div>
    )
}


function ImportStatusPage({importId, history}) {

    const {isLoading, isError, data, error} = useQuery(['importStatus', importId], () => getImportStatus(importId));
    const queryClient = useQueryClient();

    const rerunImportMutation = useMutation((importId) => rerunTransform(importId), {
        onSuccess: () => queryClient.invalidateQueries(['importStatus', importId])
    });

    const startImportMutation = useMutation((importId) => startImport(importId));

    if (isLoading)
        return (
            <div>Loading...</div>
        )

    if (isError) {
        return (
            <div>Error getting import status: {error?.message}</div>
        )
    }


    return (
        <div style={{height: "100%", display: "flex", flexDirection: "column"}}>
            <h1>Import {data.importId}</h1>
            <i>State: {data.state}</i>
            <ButtonToolbar>
                <Button variant={"danger"} onClick={async () => {
                    await deleteImport(importId);
                    history.replace('/');
                }}>Delete</Button>
                <AsyncButton isLoading={rerunImportMutation.isLoading} as={Button}
                             onClick={() => rerunImportMutation.mutate(importId)}>
                    Rerun transform
                </AsyncButton>
                <AsyncButton isLoading={startImportMutation.isLoading} as={Button} variant={"primary"} onClick={() => startImportMutation.mutate(importId)}>
                    Start Import
                </AsyncButton>
            </ButtonToolbar>
            <br/>
            {data?.state === 'ready' &&
            <Tabs defaultActiveKey={"actions"}>
                <Tab title={"Actions"} eventKey={"actions"}>
                    <ImportAnalysis importId={importId}/>
                </Tab>
                <Tab title={"Errors"} eventKey={"errors"}>
                    <ImportErrors importId={importId}/>
                </Tab>
            </Tabs>
            }
        </div>
    )

}
