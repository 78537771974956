import ndjsonStream from 'can-ndjson-stream';

const BASE_URL = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : 'http://localhost:4000';

async function checkError(response) {
    if (response.status >= 400) {
        const err = Error((await response.json()).err);
        err.statusCode = response.status;
        throw err;
    } else {
        return response;
    }
}

export async function listImports(startKey) {
    const url = `${BASE_URL}/import/list`.concat(startKey ? `?startKey=${startKey}` : '');
    const res = await fetch(url);
    const body = await checkError(res).then(res => res.json());

    if (!body || !Array.isArray(body.imports)) return {imports: [], lastKey: null};
    const {imports, lastKey} = body;
    return {imports, lastKey};
}

export async function getImportStatus(importId) {
    if (!importId) throw new Error('importId not specified');

    return fetch(`${BASE_URL}/import/${importId}/status`).then(checkError).then(res => res.json());
}

export async function createImport() {
    const {importId, uploadUrl} = await fetch(`${BASE_URL}/import/create`).then(res => res.json());
    return {importId, uploadUrl};
}

export async function uploadImport(uploadUrl, importFile) {
    if (!uploadUrl || !importFile) throw new Error('uploadUrl or importFile not specified');
    const fullUrl = uploadUrl.startsWith('/') ? `${BASE_URL}${uploadUrl}` : uploadUrl;

    const formData = new FormData();
    formData.append('import', importFile, 'import');
    const res = await fetch(fullUrl, {method: "POST", body: formData});
    return checkError(res);
}


export async function deleteImport(importId) {
    if (!importId) throw new Error('importId not specified');


    return fetch(`${BASE_URL}/import/${importId}/delete`, {method: "DELETE"});

}

export async function getTransformedImport(importId) {
    if (!importId) throw new Error('importId not specified');
    return fetch(`${BASE_URL}/import/${importId}/data/transformed`).then(checkError).then(res => ndjsonStream(res.body).getReader());
}

export async function getImportErrors(importId) {
    if (!importId) throw new Error('importId not specified');
    return fetch(`${BASE_URL}/import/${importId}/data/errors`).then(checkError).then(res => ndjsonStream(res.body).getReader());
}

export async function rerunTransform(importId) {
    if (!importId) throw new Error('importId not specified');
    return fetch(`${BASE_URL}/import/${importId}/rerun-transform`, {method: "POST"}).then(checkError);
}

export async function startImport(importId) {
    if (!importId) throw new Error('importId not specified');
    return fetch(`${BASE_URL}/import/${importId}/start`, {method: "POST"}).then(checkError);
}
