import {useCallback, useState} from "react";
import useStreamQuery from "../../hooks/useStreamQuery";
import {getImportErrors} from "../../lib/api";

export default function ImportErrors({importId}) {
    const [items, setItems] = useState([]);

    useStreamQuery({
        queryKey: ['importErrors', importId],
        query: () => getImportErrors(importId),
        onData: useCallback((chunks) => setItems(e => [...e, ...chunks]), []),
        onInit: useCallback(() => setItems([]), [])
    });

    return (
        <ul>
            {
                items.filter(i => i).map((item) => (
                    <li key={item.id}>
                        Row: {item.id} - Error: {item.type}
                    </li>))
            }
        </ul>
    )
}
