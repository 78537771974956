import {useCallback, useState} from "react";
import useStreamQuery from "../../hooks/useStreamQuery";
import {getTransformedImport} from "../../lib/api";
import styled from 'styled-components';
import {Accordion, Badge, Button, Card, ListGroup} from "react-bootstrap";


const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`

const ListContainer = styled.div`
  overflow: auto;
  height: 100%;
`

const AttributeAnalysis = ({data}) => (
    <ListGroup>
        {data?.entries?.map(item => item[1]).map(item => (
            <ListGroup.Item key={item.id}>
                <h6>{item.name} <Badge variant={"secondary"}>{item.id}</Badge></h6>
                {item.options.map(option => (
                    <div key={option.id}>
                        <i>
                            {option.id === 0 ? option.value : option.label}
                            {(!option.id) && <Badge variant={"secondary"}>To be created</Badge>}
                        </i>

                    </div>
                ))}
            </ListGroup.Item>
        ))}
    </ListGroup>
)

const CategoryAnalysis = ({data}) => (
    <>
        {data?.map(categoryPath => (
            <div key={categoryPath.map(cat => cat.id).join('')}>
                {categoryPath.sort((a, b) => a.depth - b.depth).map((category, idx) => (
                    <span key={category.id}>
                                                                {category.name}{'  '}
                        <Badge variant={"secondary"}>{category.id}</Badge>
                        {idx < categoryPath.length - 1 && ' > '}
                                                            </span>
                ))}
            </div>
        ))}
    </>
)

export default function ImportAnalysis({importId}) {
    const [items, setItems] = useState([]);

    useStreamQuery({
        queryKey: ['importAnalysis', importId],
        query: () => getTransformedImport(importId),
        onData: useCallback((chunks) => setItems(e => [...e, ...chunks]), []),
        onInit: useCallback(() => setItems([]), [])
    });

    // console.log(items[3])

    return (
        <ListContainer>
            <Accordion defaultActiveKey="0">
                {
                    items.filter(i => i).map((item, idx) => (
                        <Card key={item.id}>
                            <Card.Header>
                                <Accordion.Toggle eventKey={idx + 1} as={Button} variant={"link"}>
                                    {item.sku} {item.name && <>- {item.name}</>}
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={idx + 1}>
                                <Card.Body>
                                    {
                                        item.validActions?.includes('setCategory') && (
                                            <ActionContainer>
                                                <h5>Set category</h5>
                                                <CategoryAnalysis data={item.categories}/>
                                            </ActionContainer>
                                        )
                                    }
                                    {item.validActions?.includes('setAttributes') && (
                                        <ActionContainer>
                                            <h5>Set attributes</h5>
                                            <AttributeAnalysis data={item.attributes}/>
                                        </ActionContainer>
                                    )}
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>))
                }
            </Accordion>
        </ListContainer>
    )
}
