import {Link} from "react-router-dom";

const style = {
    width: "100%",
    height: "60px",
    background: "#1A1A1A",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white"
}

export default function Header({title}) {
    return (
        <Link to={'/'}>
            <div style={style}>
                <h3>{title}</h3>
            </div>
        </Link>
    )
}
