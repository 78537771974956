import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "./components/Header";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import ImportListPage from "./components/pages/ImportListPage";
import ImportPage from "./components/pages/ImportPage";
import {QueryClient, QueryClientProvider} from "react-query";
import PageContainer from "./components/presentational/PageContainer";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: (count, err) => count > 2 || err?.statusCode < 500
        }
    }
});

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <Router>
                <Header title={"PentruAnimale Import"}/>
                <PageContainer>
                    <Switch>
                        <Route exact path={'/'}>
                            <ImportListPage/>
                        </Route>
                        <Route path={'/import/:importId'} component={ImportPage}>
                        </Route>
                        <Route path={'*'}>
                            <div>404 Not Found</div>
                        </Route>
                    </Switch>
                </PageContainer>
            </Router>
        </QueryClientProvider>
    );
}

export default App;
