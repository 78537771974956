import {Spinner} from "react-bootstrap";

export default function AsyncButton({isLoading, as, children, style, ...props}) {
    const Component = as;
    return (
        <Component disabled={isLoading} style={{...style, display: "flex", alignItems: "center"}} {...props}>
            <div style={{flex: "none", width: "100%", visibility: isLoading ? 'hidden' : undefined}}>{children}</div>
            <div style={{flex: "none", width: "100%", marginLeft: "-100%", visibility: !isLoading ? 'hidden' : undefined}}>
                <Spinner style={{display: "inline-block", verticalAlign: "middle"}} animation={"border"}/>
            </div>
        </Component>
    )
}
