import {useState} from "react";
import * as api from '../../lib/api.js'
import InfiniteScroll from "../wrapper/InfiniteScroll";
import {Button} from "react-bootstrap";
import {Link, useHistory} from "react-router-dom";

export default function ImportListPage() {
    const [lastKey, setLastKey] = useState();
    const [initialised, setInitialised] = useState();

    const history = useHistory();

    const ImportListItem = ({importId, idx}) => (
        <Link to={`/import/${importId}`}>
            <div>Import {idx} - {importId}</div>
        </Link>
    )

    const pageCreator = (data) => (
        data.imports.map((imprt, idx) => (
            <ImportListItem importId={imprt.importId} idx={idx} key={imprt.importId}/>
        ))
    )

    return (
        <div>
            <Button onClick={async () => {
                const {importId, uploadUrl} = await api.createImport();
                history.push({pathname: `/import/${importId}`, state: {uploadUrl}})
            }}>Create import</Button>
            <InfiniteScroll next={api.listImports} queryKey={"imports"} nextPageParam={"lastKey"}
                            pageCreator={pageCreator}/>
        </div>
    )
}
