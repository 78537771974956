import {useEffect, useRef} from "react";
import {useQuery} from "react-query";

const useStreamQuery = ({query, queryKey, onData, onDone, onInit, debounceMs = 100}) => {
    const dataStream = useRef();
    const bufRef = useRef([]);
    const dataCbTime = useRef();
    const {isError, isLoading, isIdle, data, error} = useQuery(queryKey, query, {cacheTime: 0});
    if (!isError && !isLoading) dataStream.current = data;
    useEffect(() => {
        if (isError || isLoading || isIdle || !onData) return;
        const reader = dataStream.current;
        if (reader && (!reader.read || !reader.read instanceof Function))
            throw new Error('Object returned by query must be a ReadableStreamReader');

        if (onInit) onInit();
        reader.read().then(async function processStream({done, value: chunk}) {
            if (done) {
                if(bufRef.current.length > 0) onData(bufRef.current);
                if (onDone) return onDone();
                else return;
            }
            bufRef.current.push(chunk);

            if (!dataCbTime.current || dataCbTime.current + debounceMs < Date.now()){
                onData(bufRef.current);
                dataCbTime.current = Date.now();
                bufRef.current = [];
            }
            return reader.read().then(processStream);
        });
    }, [isError, isLoading, isIdle, onInit, onData, onDone, debounceMs]);
};

export default useStreamQuery;
